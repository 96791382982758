import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Page from "../components/page/page";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Container from "../components/container/container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      title
      seoDescription
      slug {
        current
      }
      _rawBody
      members {
        _key
        person {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
        roles
      }
    }
  }
`;

const PageTemplate = props => {
  const { data, errors } = props;
  const page = data && data.page;

  console.log("data", data);
  console.log("data", page);

  if (!page) {
    return null;
  }

  const metaImage =
    page.mainImage &&
    page.mainImage.asset &&
    imageUrlFor(buildImageObj(page.mainImage))
      .width(1260)
      .fit("fill")
      .url();
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {page && (
        <SEO
          title={page.title || "Axeptia"}
          image={metaImage || ""}
          description={page.seoDescription}
        />
      )}

      {errors && (
        <Container role="complementary">
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {page && <Page {...page} />}
    </Layout>
  );
};

export default PageTemplate;
