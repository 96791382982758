import React from "react";
import BlockContent from "./../block-content";
import RoleList from "./../role-list";
import Image from "../image/image";

import * as styles from "./page.module.scss";

function Page(props) {
  const { _rawBody, title, mainImage, members } = props;

  return (
    <article className={styles.root}>
      {props.mainImage && mainImage.asset && (
        <>
          <div className={styles.mainImage}>
            <Image asset={mainImage.asset} width={1260} alt={mainImage.alt} fit={"fill"}></Image>
            {mainImage.caption && <p className={styles.caption}>{mainImage.caption}</p>}
          </div>
        </>
      )}
      {title && <h1>{title}</h1>}
      <div className={styles.mainContainer}>
        <div className={styles.mainContent}>
          {_rawBody && <BlockContent blocks={_rawBody || []} />}
        </div>
        {members && members.length > 0 && (
          <div className={styles.roleList}>
            <RoleList items={members} title="Author" />
          </div>
        )}
      </div>
    </article>
  );
}

export default Page;
